import type { IMultiTypeDescriptionCases } from '~/features/cases/types/case.types';
import { Colors } from '~/constants/colors.constants';
import multicastTitle from 'assets/images/Case/MultiCase/multicast/multicastLogo.png';
import multifixTitle from 'assets/images/Case/MultiCase/multifix/multifixLogo.png';
import multifix from 'assets/images/Case/MultiCase/multifix/oggre.png';
import multicast from 'assets/images/Case/MultiCase/multicast/multicast.png';

export const enum ETypeCases {
  CASE = 'case',
  MULTICAST = 'multicast',
  MULTIFIX = 'multifix',
  NEW = 'new',
  N_FREE = 'n_free',
  UPDATE = 'update',
}

// типы кейсов мульиткаст/мультификс
export const typesCaseDescription: IMultiTypeDescriptionCases = {
  [ETypeCases.MULTICAST]: {
    additionalCard: {
      avatar: {
        image: { png1: multicast },
      },
      colorBg: Colors.GRADIENTS.MULTICAST,
      colorText: Colors.ADDITIONAL.MULTICAST,
      descriptionColor: Colors.ADDITIONAL.PEACH,
      title: {
        image: { png1: multicastTitle },
        size: {
          sm: {
            height: '16px',
            width: '106px',
          },
          xl: {
            height: '20px',
            width: '130px',
          },
        },
      },
    },
  },
  [ETypeCases.MULTIFIX]: {
    additionalCard: {
      avatar: {
        image: { png1: multifix },
      },
      colorBg: Colors.GRADIENTS.MULTIFIX,
      colorText: Colors.ADDITIONAL.MULTIFIX,
      descriptionColor: Colors.ADDITIONAL.PURPLE,
      title: {
        image: { png1: multifixTitle },
        size: {
          sm: {
            height: '25px',
            width: '120px',
          },
          xl: {
            height: '29px',
            width: '130px',
          },
        },
      },
    },
  },
  // На данный момент эта инфа необходима только в Мультикаст/Мультификс
};
