import { ErrorCodes } from '../codes/codes';
import type { IError } from '~/repository/extensions/error/error.types';

// Используем готовые сообщения для обработки предвиденных ошибок
export default class MessageError {
  static get DataNotFound(): IError {
    return {
      key: ErrorCodes.DATA_NOT_FOUND,
      msg: ErrorCodes.DATA_NOT_FOUND,
    };
  }
}
