import { defineStore, skipHydrate } from 'pinia';
import type {
  ICaseDropItem,
  IItemCaseData,
  ISimplifiedPreviewItem,
  ITypeDescriptionCase,
} from '~/features/cases/types/case.types';
import type { Dto } from '~/repository/modules/cases';
import { ETypeCases, typesCaseDescription } from '~/features/cases/constants/case.constants';
import type {
  ICaseData,
  IImageData,
  IOpenItems,
  ISection,
  TChallangeData,
  TTestyCoins,
} from '~/repository/modules/cases/cases.types';
import type { TPossibleNull } from '~/types/Shared.types';
import MessageError from '~/api/global/errors/messages/MessageError';
import { useAlertStore } from '~/store/alert/alert.store';
import { useFinalDropsStore } from '~/features/cases/store/useFinalDrops.store';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useCarouselStore } from '~/features/cases/store/useCarousel.store';
import { useUserStore } from '~/store/user/user.store';
import { useDropWinnersStore } from '~/features/cases/store/dropWinners.store';
import { useVolumeStore } from '~/store/volume/volume';
import { getLabelData } from '~/features/mainPage/Types/cases/adapter';
import { CaseEvents } from '~/repository/amplitude/events/case';

export const useCaseStore = defineStore('cases/case', () => {
  // import
  const {
    $api: { cases: CaseApi },
    $i18n: { t },
  } = useNuxtApp();

  const isLoaded = ref(false);

  const alertStore = useAlertStore();
  const finalDropsStore = useFinalDropsStore();
  const carouselStore = useCarouselStore();
  const userStore = useUserStore();
  const dropsWinners = useDropWinnersStore();
  const volumeStore = useVolumeStore();
  const { dropsWinner, dropWinnerTastyCoins } = storeToRefs(dropsWinners);
  const { resetDropsWinner } = dropsWinners;

  const isCaseOpenInProcess = ref<boolean>(false);

  // массив всех кейсов
  const allCases = ref<ISection[]>([]);

  const caseData = ref<IItemCaseData>();
  // Фон страницы кейса
  const caseBg = ref<IImageData>({} as IImageData);
  // используется для задачи имени из роута
  const caseName = ref<string>('');
  // сведения о прогрессе для информера в ивентных кейсах
  const progressData = ref<TPossibleNull<TChallangeData>>(null);
  // формат отображения описания кейсов в зависимости от типа
  const activeTypeDescriptionCase = ref<ITypeDescriptionCase>();
  // сведения о наличии коинов в кейсе - критерий является ли ивентным
  const caseCoins = ref<TPossibleNull<TTestyCoins>>(null);

  // модель для отслеживания ошибки открытия кейса
  const isCaseOpenSuccess = ref<boolean>(true);

  const caseMultiplier = ref<number>(1);
  const caseType = ref<ETypeCases>(ETypeCases.CASE);
  const caseLoadingState = reactive({
    caseDataLoading: false,
    caseStartLoading: false,
    enoughMoneyCaseLoading: false,
    eventCasesLoading: false,
  });

  const multiCasePrice = computed(() => {
    if (!caseData.value || !caseData.value.priceData || !caseData.value.priceData.price) return 0;
    return +caseData.value.priceData.price;
  });
  const isCaseAvailableByPrice = computed(() => {
    if (!userStore.user || !userStore.userBalance) return false;
    return +userStore.userBalance - multiCasePrice.value >= 0;
  });

  const isQuickOpening = ref(false);

  const eventCases = ref<IItemCaseData[]>([]);
  const enoughMoneyCase = ref<IItemCaseData>();

  const ultraItems = ref<ISimplifiedPreviewItem[]>([]);
  const otherItems = ref<ISimplifiedPreviewItem[]>([]);

  // Время входа на страницу кейса - для аналитики
  const startTimeThisCase = ref<Date | null>(null);

  const caseBackground = computed(() => {
    return caseData.value?.imageData || {};
  });

  const isShowSoldAllThingsBtn = computed(
    () => isCaseAvailableByPrice.value && multiCasePrice.value <= +(userStore.user?.finance.totalAmountItems || 0),
  );

  const setCaseName = (name: string) => {
    caseName.value = caseData?.value ? caseData?.value?.name : name;
  };

  /**
   * Получает базовый кейс по названию
   * @param {string} caseName - наименование кейса
   * @returns {Promise<void>}
   */
  const fetchCase = async (caseName: string): Promise<void> => {
    if (!caseName) return;
    caseLoadingState.caseDataLoading = true;
    isLoaded.value = false;

    try {
      const response: TPossibleNull<Dto.CaseResponseDto> = await CaseApi.getCase(caseName);

      if (!response) return;
      if (!response.caseData) throw new Error('No case data fetched');
      if (!response.dropList) throw new Error('No case items fetched');

      const labelData = { ...response.caseData.labelData, ...getLabelData(response.caseData, t) };
      caseData.value = { ...response.caseData, labelData };

      caseMultiplier.value = response.caseData.multicastData?.count || response.caseData.multifix || 1;
      fillItems(response.dropList);
      defineCaseType(response.caseData);
      caseBg.value = response?.caseData?.imageData || ({} as IImageData);
      if (typeof response?.caseData?.tastyCoins !== 'undefined') caseCoins.value = response?.caseData?.tastyCoins;
      carouselStore.setCarousels(response.dropList);
      // Изначально устанавливаем в качестве выигранного предмета самый дешевый
      carouselStore.setCheapestItemAsWonDrop(response.dropList);
      setActiveTypeCase();
    } catch (e) {
      alertStore.showError({
        message: (e as { msg?: string }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      caseLoadingState.caseDataLoading = true;
      isLoaded.value = true;
    }
  };

  const getOpenProfit = (sum: number, price: number) => {
    const delta = sum - price;

    return {
      money: Math.floor(delta),
      percent: Math.floor((delta / price) * 100) + '%',
    };
  };

  const getDefaultAmplitudeData = () => {
    if (!caseData.value) return;

    const { id, label, type, priceData, tastyCoins } = caseData.value;
    const parsedPrice = +priceData.price;

    return {
      'Case Currency': priceData.currency,
      'Case ID': id,
      'Case Name': label,
      'Case Price': parsedPrice,
      'Case Type': type || 'case',
      'Is Free Case': parsedPrice === 0,
      'Is Giving Coins': tastyCoins !== null,
    };
  };

  /**
   * Открыть кейс
   * @param {string} caseName - наименование кейса
   * @param {boolean} isQuick - тип открытия кейса
   * @returns {Promise<void>}
   */
  const openCase = async (caseName: string, isQuick: boolean): Promise<boolean | void> => {
    resetDropsWinner();
    try {
      const openedCase: TPossibleNull<IOpenItems> = await CaseApi.openCase(caseName);
      if (!openedCase) throw MessageError.DataNotFound;

      const { items, tastyCoins, prizeSegment } = openedCase;
      if (!items) throw MessageError.DataNotFound;

      const amplitudeData = getDefaultAmplitudeData();
      if (amplitudeData && caseData.value) {
        const { priceData } = caseData.value;
        const totalItemsSum = items.reduce((acc, item) => {
          acc += +item.dropItemData.priceData.price;
          return acc;
        }, 0);

        const { money: rubProfit, percent: percentProfit } = getOpenProfit(totalItemsSum, +priceData.price);

        CaseEvents.caseOpened({
          ...amplitudeData,
          'Fast Open': isQuick,
          'Is Sound Enabled': !volumeStore.isMuteCookie,
          'User Profit in Percent': percentProfit,
          'User Profit in RUB': rubProfit,
        });
      }

      finalDropsStore.setFinalDrop(items);

      finalDropsStore.setTastyCoins(tastyCoins || 0);
      finalDropsStore.setPrizeSegment(prizeSegment);

      dropsWinner.value = items || [];
      dropWinnerTastyCoins.value = tastyCoins;
      caseMultiplier.value = items?.length;

      if (caseData.value?.multicastData?.count || caseData.value?.multifix) {
        carouselStore.cloneCarousels(caseMultiplier.value);
      }

      // Пробегаем по всем выигранным предметам - 1 предмет = 1 рулетка
      // eslint-disable-next-line
      items.forEach((_, idx) => carouselStore.appendWinnerItem(idx, items[idx].dropItemData));
      return true;
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: t('alerts.errorOpeningCase'),
      });
      isCaseOpenSuccess.value = false;
      isCaseOpenInProcess.value = false;
      carouselStore.cloneCarousels(1);
      return false;
    }
  };

  const fetchEventCases = async () => {
    caseLoadingState.eventCasesLoading = true;
    try {
      const cases: ICaseData[] | undefined = await CaseApi.getEventCases();
      eventCases.value = cases || [];
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      caseLoadingState.eventCasesLoading = false;
    }
  };

  const fetchEnoughUserMoneyCase = async () => {
    caseLoadingState.enoughMoneyCaseLoading = true;
    try {
      const response = await CaseApi.getMaxCasePriceForUser();
      if (!response) return;
      enoughMoneyCase.value = response;
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      caseLoadingState.enoughMoneyCaseLoading = false;
    }
  };

  const fetchChallangeProgress = async () => {
    caseLoadingState.eventCasesLoading = true;
    try {
      const data: TPossibleNull<TChallangeData> = await CaseApi.getChallangeProgress();

      progressData.value = data;
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      caseLoadingState.eventCasesLoading = false;
    }
  };

  const isEventCase = computed<boolean>(() => {
    return caseCoins.value !== null;
  });

  // TODO избавиться
  const fetchSections = async (): Promise<void> => {
    try {
      const data = await CaseApi.getSections();

      if (data) {
        allCases.value = data;
      }
    } catch (e) {
      alertStore.showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
      const localeRoute = useLocaleRoute();
      navigateTo(localeRoute('/'));
    }
  };

  function defineCaseType(caseData: IItemCaseData) {
    if (caseData.multifix) caseType.value = ETypeCases.MULTIFIX;
    else if (caseData.multicastData?.count) caseType.value = ETypeCases.MULTICAST;
    else if (caseData.labelData.freeOpens !== null) caseType.value = ETypeCases.N_FREE;
    else if (caseData.labelData?.type === 'new') caseType.value = ETypeCases.NEW;
    caseType.value = ETypeCases.CASE;
  }

  function fillItems(allItems: ICaseDropItem[] = []) {
    const sortedAscItems = allItems
      .map(({ id, name, qualityEnum, priceData, imageData, isUltraRare, type }) => ({
        id,
        image: imageData.image,
        isUltraRare,
        name,
        priceData,
        quality: qualityEnum.name,
        type,
      }))
      .sort((a, b) => +b.priceData.price - +a.priceData.price) as ISimplifiedPreviewItem[];
    for (const sortedItem of sortedAscItems) {
      const isUltraItemInArray = ultraItems.value.find((item) => item.id === sortedItem.id);
      if (sortedItem.isUltraRare && !isUltraItemInArray) {
        ultraItems.value.push(sortedItem);
        continue;
      }
      const isItemInArray = otherItems.value.find((item) => item.id === sortedItem.id);
      if (isItemInArray || sortedItem.isUltraRare) continue;
      otherItems.value.push(sortedItem);
    }
  }

  // Метод хелпер, опеределяет тип активного кейса
  const setActiveTypeCase = (): void => {
    if (!caseData.value) return;
    if (caseData.value.multifix) {
      activeTypeDescriptionCase.value = typesCaseDescription.multifix;
      return;
    }

    if (caseData.value.multicastData?.count) {
      activeTypeDescriptionCase.value = typesCaseDescription.multicast;
    }
  };

  const clearItemCase = () => {
    caseData.value = undefined;
  };

  /** Метод вычисления значений для аналитики **/
  const calculateStandEventAmplitude = () => {
    if (!startTimeThisCase.value) return;
    const lastDate = new Date();
    const outTimeThisCase = Math.floor((+lastDate - +startTimeThisCase.value) / 1000);

    const amplitudeData = getDefaultAmplitudeData();
    if (!amplitudeData) return;

    CaseEvents.caseLeave({
      ...amplitudeData,
      'Case Out': outTimeThisCase,
    });
  };

  const sendCaseEnteredAmplitude = () => {
    startTimeThisCase.value = new Date();

    const amplitudeData = getDefaultAmplitudeData();
    if (!amplitudeData || !caseData.value) return;

    const { id } = caseData.value;
    const categoryObj = allCases.value.find(({ cases }) => cases.find(({ id: caseId }) => caseId === id));

    CaseEvents.caseEntered({
      ...amplitudeData,
      Category: categoryObj?.name || '',
    });
  };

  return {
    activeTypeDescriptionCase,
    allCases,
    calculateStandEventAmplitude,
    caseBackground,
    caseBg,
    caseData,
    caseLoadingState,
    caseMultiplier,
    caseName,
    caseType,
    clearItemCase,
    enoughMoneyCase,
    eventCases,
    fetchCase,
    fetchChallangeProgress,
    fetchEnoughUserMoneyCase,
    fetchEventCases,
    fetchSections,
    isCaseAvailableByPrice,
    isCaseOpenInProcess,
    isCaseOpenSuccess,
    isEventCase,
    isLoaded,
    isQuickOpening,
    isShowSoldAllThingsBtn,
    multiCasePrice,
    openCase,
    otherItems: skipHydrate(otherItems),
    progressData,
    sendCaseEnteredAmplitude,
    setActiveTypeCase,
    setCaseName,
    ultraItems: skipHydrate(ultraItems),
  };
});
