import { ECaseEvents, type ICaseEnteredEvent, type ICaseLeaveEvent, type ICaseOpenedEvent } from '../types/case.types';

export class CaseEvents {
  static caseEntered(event: ICaseEnteredEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: ECaseEvents.CASE_ENTERED,
      eventProperties: event,
    });
  }

  static caseLeave(event: ICaseLeaveEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: ECaseEvents.CASE_LEAVE,
      eventProperties: event,
    });
  }

  static caseOpened(event: ICaseOpenedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: ECaseEvents.CASE_OPENED,
      eventProperties: event,
    });
  }
}
